import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styles from "./navigation-link.module.css"
import NavigationDropdown from "./navigation-dropdown/navigation-dropdown"

export default function NavigationLink({
  children,
  dropdownItems,
  to,
  partiallyActive,
}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const openDropdown = () => {
    if (dropdownItems) {
      setShowDropdown(true)
    }
  }

  const closeDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <Link
      to={to}
      className={styles.NavigationLink}
      activeClassName={styles.ActiveNavigationLink}
      partiallyActive={partiallyActive}
      onMouseOver={openDropdown}
      onMouseLeave={closeDropdown}
    >
      {children}
      {showDropdown ? <NavigationDropdown items={dropdownItems} /> : null}
    </Link>
  )
}
