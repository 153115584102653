import React, { useState } from "react"
import Logo from "../../../logo/logo"
import NavigationLinks from "../navigation-links/navigation-links"
import styles from "./navigation-mobile.module.css"

export default function NavigationMobile() {
  const [open, setOpen] = useState(false)

  function toggle() {
    setOpen(!open)
  }

  let burgerStyles = [styles.Burger]
  let drawerStyles = [styles.Drawer, styles.DrawerClosed]
  let backdropStyles = [styles.Backdrop, styles.BackdropClosed]
  let burgerPlaceholderStyle = styles.BurgerPlaceholderHide

  if (open) {
    drawerStyles = [styles.Drawer, styles.DrawerOpen]
    backdropStyles = [styles.Backdrop, styles.BackdropOpen]
    burgerPlaceholderStyle = styles.BurgerPlaceholderShow
    burgerStyles = [styles.Burger, styles.Fixed]
  }

  return (
    <div className={styles.NavigationMobile}>
      <div className={burgerStyles.join(" ")}>
        <input
          type="checkbox"
          checked={open}
          onChange={() => {
            toggle()
          }}
        />
        <span className={styles.BurgerTop}></span>
        <span className={styles.BurgerMiddle}></span>
        <span className={styles.BurgerBottom}></span>
      </div>
      <div className={burgerPlaceholderStyle}></div>
      <Logo />
      <div className={drawerStyles.join(" ")}>
        <NavigationLinks />
      </div>
      <div className={backdropStyles.join(" ")} onClick={toggle}></div>
    </div>
  )
}
