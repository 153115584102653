import React from "react"
import { Link } from "gatsby"
import NavigationLinks from "../navigation-links/navigation-links"
import Logo from "../../../logo/logo"
import styles from "./navigation-desktop.module.css"

export default function NavigationDesktop() {
  return (
    <nav className={styles.NavigationDesktop}>
      <Link to="/" className={styles.LogoLink}>
        <Logo />
      </Link>
      <span className={styles.LinkContainer}>
        <NavigationLinks />
      </span>
    </nav>
  )
}
