import React from "react"
import { Link } from "gatsby"
import NavigationDesktop from "../navigation/navigation-desktop/navigation-desktop"
import Logo from "../../logo/logo"
import styles from "./header.module.css"
import NavigationMobile from "../navigation/navigation-mobile/navigation-mobile"

export default function Header({ scrollPosition }) {
  return (
    <header
      className={styles.header}
      style={scrollPosition > 50 ? { marginTop: "-50px" } : null}
    >
      <div className={styles.topbar}>
        <div className={styles.topbarInner}>
          Need advice? Call us now on:
          <a
            href="tel:020 3581 2854"
            className="link"
            style={{ marginLeft: "10px" }}
          >
            <b>0203 581 2854</b>
          </a>
          <Link to="/contact" className={styles.button}>
            Request a Call Back
          </Link>
        </div>
      </div>
      <div className={styles.navigation}>
        <NavigationDesktop />
        <NavigationMobile />
      </div>
    </header>
  )
}

// {scrollPosition > 100 ? null : (
//   <div className={styles.topbar}>
//     Need Advice? Call us now on: 0203 581 2854
//     <Link to="/contact" className={styles.button}>
//       Request a Call Back
//     </Link>
//   </div>
// )}
