import React from "react"
import { Link } from "gatsby"
import styles from "./navigation-dropdown.module.css"

export default function NavigationDropdown({ items }) {
  const dropdownItems = items.map((item, index) => (
    <Link className={styles.dropdownLink} key={index} to={item.to}>
      {item.name}
    </Link>
  ))

  return <div className={styles.dropdown}>{dropdownItems}</div>
}
