import React from "react"
import NavigationLink from "./navigation-link/navigation-link"

export default function NavigationLinks() {
  const servicesDropdownItems = [
    { name: "Immigration", to: "/services/immigration" },
    { name: "Criminal Law", to: "/services/criminal-law" },
    { name: "Family Law", to: "/services/family-law" },
    { name: "Personal Injury", to: "/services/personal-injury" },
    { name: "Civil Litigation", to: "/services/civil-litigation" },
    { name: "Wills in Islam", to: "/services/wills-in-islam" },
  ]

  const aboutDropdownItems = [
    { name: "Charges & Fees", to: "/about/charges-and-fees" },
    { name: "Our Team", to: "/about/our-team" },
    { name: "Complaints", to: "/about/complaints-procedure" },
  ]

  return (
    <>
      <NavigationLink to="/">Home</NavigationLink>
      <NavigationLink
        to="/about"
        dropdownItems={aboutDropdownItems}
        partiallyActive={true}
      >
        About Us
      </NavigationLink>
      <NavigationLink
        to="/services"
        dropdownItems={servicesDropdownItems}
        partiallyActive={true}
      >
        Services
      </NavigationLink>
      <NavigationLink to="/contact">Contact Us</NavigationLink>
    </>
  )
}
