import React, { useState, useEffect } from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import { Helmet } from "react-helmet"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faPhone,
  faMobileAlt,
  faEnvelopeSquare,
  faMapMarkerAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons"
import "./layout.css"

library.add(faPhone, faMobileAlt, faEnvelopeSquare, faMapMarkerAlt, faFax)

export default function Layout({ children }) {
  const [paddingBottom, setPaddingBottom] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  const updatePaddingBottom = () => {
    let footer = document.querySelector("footer")
    let compStyles = window.getComputedStyle(footer)
    let height = compStyles.getPropertyValue("height")
    setPaddingBottom(height)
  }

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(updatePaddingBottom)

  useEffect(() => {
    window.addEventListener("resize", updatePaddingBottom)
    return () => {
      window.removeEventListener("resize", updatePaddingBottom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className="layout">
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header scrollPosition={scrollPosition} />
      <main className="main" style={{ paddingBottom: paddingBottom }}>
        {children}
      </main>
      <Footer />
    </div>
  )
}
