import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"

export default function Footer() {
  return (
    <footer>
      <div className={styles.banner}>
        <h1>Got a question? </h1>
        <h1>We are here to help, call us today on</h1>
        <h1>
          <a href="tel:020 3581 2854">020 3581 2854</a>
        </h1>
      </div>
      <div className={styles.footer}>
        <div className={styles.links}>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact Us</Link>
          <Link to="/site-map">Site Map</Link>
        </div>

        <p>
          Limited Company Registration No: 11116772 Vat No: 294633572 JJ Law
          Chambers is the trading name of JJ Law Chambers Limited. Authorised
          and regulated by the Solicitors Regulation Authority. SRA. No. 646287
        </p>

        <div>© JJ Law Chambers</div>
      </div>
    </footer>
  )
}
